@use '@/styles/utils/mixins.scss' as *;

.ctaBannerWrapper {
  margin-top: 2.5rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.75rem;
  @include tab() {
    margin-top: 5rem;
    gap: 3.75rem;
  }

  .ctaBannerContent {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }
  .metaData {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 0.5rem;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    color: $neutral07;
    @include mob() {
      flex-direction: column;
      align-items: flex-start;
      gap: 1.25rem;
    }
    li {
      display: flex;
      gap: 0.5rem;
      align-items: baseline;
      &:not(:last-child)::after {
        content: '|';
        @include mob() {
          content: none;
        }
      }
    }
  }
  .bannerImageContainer {
    display: flex;
    align-items: center;
  }

  .bannerImage {
    max-width: 100%;
    height: auto;
    border-radius: 1.25rem;
  }
}
